/*! --------------------------------------------------------------------
  BUROCRATIK's PARALLAX (jQuery plugin)
* @Version:    1.0.0 - 2015
* @author:     Burocratik (alexandre gomes - @alexrgomes)
* @email:      alex@burocratik.com, hello@burocratik.com
* @website:    http://www.burocratik.com
*
* Originally idea by Drew Wilson, "atvIMG" :: https://github.com/drewwilson/atvImg

-----------------------------------------------------------------------*/



/*-----------------------------------------------------------------------
> NOTE:: - If i want bottom layer to parallax I have to add a dummy layer before that one
         - don't need shide html if i don't use it
         - not working on FF
 -----------------------------------------------------------------------*/
;(function(defaults, $, window, document, undefined) {
    'use strict';
    $.extend({
        // Function to change the default properties of the plugin
        // Usage: jQuery.pluginSetup({property:'Custom value'});
        // animateSpriteIMGSetup : function(options) {
        //     return $.extend(defaults, options);
        // }
    }).fn.extend({
        // Usage: jQuery(selector).pluginName({property:'value'});
        buroParallax : function(options) {
            options = $.extend({}, defaults, options);

            return $(this).each(function() {
                //** PLUGIN LOGIC
                var $el = $(this),
                    $el_js = $el[0],
                    $shine = null,
                    $layers = $el.find(".parallax-b-layer"),
                    totalLayerElems = $layers.length,
                    $d = document,
                    $win = window,
                    $bd = $d.getElementsByTagName('body')[0],
                    supportsTouch = 'ontouchstart' in $win || navigator.msMaxTouchPoints;

                var w = $el_js.clientWidth || $el_js.offsetWidth || $el_js.scrollWidth;
                if ( options.usePerspective ) {
                  $el_js.style.transform = 'perspective('+ w*3 +'px)';
                }


                if ( options.shineGlow ) var $shine = $el.find(".parallax-b-shine");

                if ( supportsTouch ){
                    $win.preventScroll = false;
                    $el.on("touchmove", function(e){
                      if ($win.preventScroll){ e.preventDefault(); }
                      var $this = $(this);
                      processMovement(e,false,$this, $layers,totalLayerElems,$shine);
                    });
                    $el.on("touchstart", function(e){
                      $win.preventScroll = true;
                      var $this = $(this);
                      processEnter(e,$this);
                    });
                    $el.on("touchend", function(e){
                      $win.preventScroll = false;
                      var $this = $(this);
                      processMovement(e,false,$this, $layers,totalLayerElems,$shine);
                    });
                  } else {
                    $el.mousemove(function(e){
                      var $this = $(this);
                      processMovement(e,false,$this, $layers,totalLayerElems,$shine);
                    });

                    $el.mouseenter(function(e){
                      var $this = $(this);
                      processEnter(e,$this);
                    });
                    $el.mouseleave(function(e){
                      var $this = $(this);
                      processExit(e,$this,$layers,$shine);
                    });
               //END EVENTS
              }

                function processMovement(e, touchEnabled, $elem, $layers, totalLayerElems, $shine){
                    var $el_js = $elem[0],
                        $container = $elem.find(".parallax-b-container"),
                        bdst = $bd.scrollTop,
                        bdsl = $bd.scrollLeft,
                        pageX = (touchEnabled)? e.touches[0].pageX : e.pageX,
                        pageY = (touchEnabled)? e.touches[0].pageY : e.pageY,
                        offsets = $el_js.getBoundingClientRect(),
                        w = $el_js.clientWidth || $el_js.offsetWidth || $el_js.scrollWidth, // width
                        h = $el_js.clientHeight || $el_js.offsetHeight || $el_js.scrollHeight, // height
                        wMultiple = 320/w,
                        offsetX = 0.52 - (pageX - offsets.left - bdsl)/w, //cursor position X
                        offsetY = 0.52 - (pageY - offsets.top - bdst)/h, //cursor position Y
                        dy = (pageY - offsets.top - bdst) - h / 2, //@h/2 = center of container
                        dx = (pageX - offsets.left - bdsl) - w / 2, //@w/2 = center of container
                        yRotate = (offsetX - dx)*(options.rotateXdx * wMultiple), //rotation for container Y
                        xRotate = (dy - offsetY)*(options.rotateYdy * wMultiple), //rotation for container X
                        imgCSS = 'rotateX(' + xRotate + 'deg) rotateY(' + yRotate + 'deg)', //img transform
                        arad = Math.atan2(dy, dx), //angle between cursor and center of container in RAD
                        angle = arad * 180 / Math.PI - 90; //convert rad in degrees

                      //get angle between 0-360
                      if (angle < 0) angle = angle + 360;

                      //container transform, scale
                      if ( options.scale ) {
                        if ( $container.hasClass("over") ){
                          imgCSS += ' scale3d('+options.scaleValue+','+options.scaleValue+','+options.scaleValue+')';
                        }
                      }//end has scale

                      if ( options.rotate ) {
                        $container.css("transform", imgCSS)
                      }
                      //$container[0].style.transform = imgCSS;

                      //shine
                      if ( options.shineGlow ) {
                        $shine[0].style.background = 'linear-gradient(' + angle + 'deg, rgba(255,255,255,' + (pageY - offsets.top - bdst)/h * 0.4 + ') 0%,rgba(255,255,255,0) 100%)';
                        $shine[0].style.transform = 'translateX(' + (offsetX * totalLayerElems) - 0.1 + 'px) translateY(' + (offsetY * totalLayerElems) - 0.1 + 'px)';
                      }
                      //paralax
                      var revNum = totalLayerElems;
                      $layers.each(function(i) {
                          var $this = $(this)[0];
                          $this.style.transform = 'translateX(' + (offsetX * revNum) * ((i * 2.5) / wMultiple) + 'px) translateY(' + (offsetY * totalLayerElems) * ((i * 2.5) / wMultiple) + 'px)';
                          revNum--;
                      })
                //END PROCESS MOVE
                }

                function processEnter(e, $elem) {
                  var $container = $elem.find(".parallax-b-container");
                      $container.addClass("over");
                //processEnter
                }

                function processExit(e, $elem, $layers, $shine){
                    var $container = $elem.find(".parallax-b-container");

                    if ( options.resetPositions ) {
                      $container.removeClass("over");
                      $container.attr("style", "");
                      $layers.attr("style", "");
                      if ( options.shineGlow ) $shine.attr("style", "");
                    }

                //processExit
                }

                //**CONDITONS COLLED BY METHODS
                if ( options.pause ) {

                }

                if ( options.destroy ) {

                }
            //END PLUGIN LOGIC (return each)
            });
        },

        pause : function( options )  {
            var $el = $(this);
            $el.buroParallax({
                pause: true
            });
            return $.extend(defaults, options);
            // Some logic , Calling the function: jQuery(selector).otherMethod(options);
        },
        destroy : function( options ) {
            var $el = $(this);
            $el.buroParallax({
                destroy: true
            });
            return $.extend(defaults, options);
        }
    });
})({
    scale: false,
    scaleValue: 1,
    rotate: false,
    rotateYdx: 0,
    rotateXdy: 0,
    usePerspective: true,
    shineGlow: false,
    resetPositions: true,
    destroy: false
}, jQuery, window, document);
